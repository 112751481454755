import toast from 'react-hot-toast';

function errToast(message) {
  toast.error(message, {
    style: {
      background: 'red',
      color: 'white'
    }
  })
}

function successToast(message) {
  toast.success(message, {
    style: {
      background: 'green',
      color: 'white'
    }
  })
}

export { errToast, successToast };
