import { useEffect } from 'react'
import { Routes, Route } from 'react-router'
import { connect } from 'react-redux'
import { useAsyncFn, useAsync } from 'react-use'

import List from './list'
import Buy from './buy'

import { getResources, getTicketPrices, getRolePrices, getRoles, profile } from '../../lib/api'
import useInterval from '../../hooks/useInterval'
import { errToast, successToast } from '../../lib/toast';

import Loader from '../../components/loader'
import Banner from '../../components/banner'
import useQuery from '../../hooks/useQuery'

import { actions as notificationActions } from '../../redux/notification'

import styles from './index.module.scss'

function mapDispatchToProps(dispatch) {
  return {
    addNotification: (text, options) => dispatch(notificationActions.addNotification(text, options))
  }
}

function Tickets() {
  const { query, deleteFromQuery } = useQuery()

  const { value: resources = [], loading: resourcesLoading } = useAsync(getResources)
  const { value: ticketPrices = [], loading: loadingTicketPrices } = useAsync(getTicketPrices);
  const { value: rolePrices = [], loading: rolePricesLoading } = useAsync(getRolePrices);
  const { value: roles = [], loading: rolesLoading } = useAsync(getRoles);
  const [user, fetchUser] = useAsyncFn(profile);

  useInterval(fetchUser, 50000)

  useEffect(() => {
    fetchUser()
  }, [fetchUser])

  useEffect(() => {
    if (query.get('payment') === 'success') {
      successToast('Payment succesful')
    }

    if (query.get('payment') === 'failed') {
      errToast('Payment failed')
    }

    if (query.get('payment') === 'cancelled') {
      errToast('Payment cancelled')
    }

    deleteFromQuery('payment')
  }, [query, deleteFromQuery])

  const isLoading = resourcesLoading || loadingTicketPrices || rolePricesLoading || rolesLoading || user.loading

  if (isLoading) return <Loader />

  return (
    <>
      <Banner />
      <div className={styles.container}>
        <Routes>
          <Route
            index
            element={
              <List
                loading={isLoading}
                resources={resources}
                roles={roles}
                user={user.value}
              />
            }
          />
          <Route
            path="/buy"
            element={
              <Buy
                resources={resources}
                ticketPrices={ticketPrices}
                loading={isLoading}
                rolePrices={rolePrices}
              />
            }
          />
        </Routes>
      </div >
    </>
  )
}

export default connect(
  undefined,
  mapDispatchToProps
)(Tickets)
