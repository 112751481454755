import { useEffect } from 'react'
import { useAsyncFn } from 'react-use'
import { actions as notificationActions } from '../../../redux/notification'
import { connect } from 'react-redux'
import { isEmpty, omit } from 'lodash'

import Resources from './resource/index'
import Loader from '../../../components/loader'

import styles from './index.module.scss'

import {
  getResourcesAdmin,
  getRoles,
  createResource,
  deleteResource,
  saveResource,
  getCalendars,
  assignResources
} from '../../../lib/api'

function mapDispatchToProps(dispatch) {
  return {
    addNotification: (text, options) => dispatch(notificationActions.addNotification(text, options)),
  }
}

function AdminResources({ addNotification }) {
  const [resources, fetchResources] = useAsyncFn(getResourcesAdmin)
  const [roles, fetchRoles] = useAsyncFn(getRoles)
  const [calendars, fetchCalendars] = useAsyncFn(getCalendars)

  useEffect(() => {
    fetchResources()
    fetchRoles()
    fetchCalendars()
  }, [
    fetchResources,
    fetchRoles,
    fetchCalendars
  ])

  const createResourceHandler = async (values) => {
    const { resource, roleIDs } = values
    const resourceID = await createResource(omit(resource, "roleIDs"))
    const parsedRoleIDS = resource.roleIDs.map(r => parseInt(r))

    if (!isEmpty(parsedRoleIDS)) {
      assignResources(resourceID, parsedRoleIDS)
    }

    addNotification('resource created')
    await fetchResources()
    await fetchRoles()
  }

  const deleteResourceHandler = async (resourceID) => {
    await deleteResource(resourceID)
    addNotification('resource deleted')
    fetchResources()
  }

  const saveResourceHandler = async (resourceID, values) => {
    const { resource, roleIDs } = values

    resource.isFree = resource.isFree === 1 ? true : false

    await saveResource(resourceID, omit(resource, ['resourceID', 'organisationID', 'roles', 'roleIDs']))

    if (!isEmpty(roleIDs)) {
      const parsedRoleIDS = roleIDs.map(r => parseInt(r))
      assignResources(resourceID, parsedRoleIDS)
    }

    addNotification('resource saved')
    await fetchResources()
    await fetchRoles()
  }

  const loading = resources.loading || roles.loading || calendars.loading

  if (loading) return <Loader />

  return (
    <div className={styles.container}>
      <Resources
        resources={resources.value}
        createResource={createResourceHandler}
        deleteResource={deleteResourceHandler}
        saveResource={saveResourceHandler}
        roles={roles.value}
        calendars={calendars.value}
      />
    </div>
  )
}

export default connect(
  null,
  mapDispatchToProps
)(AdminResources)
