import { useEffect, useState, useReducer } from 'react'
import { Form, Col, InputGroup, Row } from 'react-bootstrap'
import { AiOutlineSearch } from 'react-icons/ai'
import { filter } from 'lodash'
import { useAsyncFn } from 'react-use'
import { connect } from 'react-redux'
import { isEmpty, omit } from 'lodash'

import UserTable from './user-table'

import { actions as notificationActions } from '../../../redux/notification'

import styles from './index.module.scss'
import Loader from '../../../components/loader'

import {
  getUsers,
  getRoles,
  deleteUser,
  updateUser,
  resendActivation
} from '../../../lib/api'

function mapDispatchToProps(dispatch) {
  return {
    addNotification: (text, options) => dispatch(notificationActions.addNotification(text, options)),
  }
}

function AdminUsers({ addNotification }) {

  const [usersData, setUsersData] = useState([])
  const [selectedUser, setSelectedUser] = useState(0)
  const [search, setSearch] = useState('')
  const forceUpdate = useReducer(() => ({}))[1]

  const [users, fetchUsers] = useAsyncFn(getUsers)
  const [roles, fetchRoles] = useAsyncFn(getRoles)

  const deleteUserHandler = async (userID) => {
    await deleteUser(userID)
    addNotification('user deleted')
    fetchUsers()
  }

  const updateUserHandler = async (user) => {
    await updateUser(user.userID, omit(user, [
      'organisationID',
      'userID',
      'email',
      'corporation',
      'isVerified',
      'roleExpires',
      'isAdmin'
    ]))
    addNotification('user edited')
    forceUpdate()
  }

  const ticketAddHandler = (userID, amount) => {
    const user = users.value.find(u => u.userID === userID)
    user.tickets = user.tickets + amount
    setUsersData(filterUsers(users))
    updateUserHandler(user)
  }

  const ticketRemoveHandler = (userID, amount) => {
    const user = users.value.find(u => u.userID === userID)
    user.tickets = user.tickets - amount
    setUsersData(filterUsers(users))
    updateUserHandler(user)
  }

  const setRoleHandler = (userID, roleID) => {
    const user = users.value.find(u => u.userID === userID)
    user.roleID = roleID
    setUsersData(filterUsers(users))
    updateUserHandler(user)
  }

  const resendActivationHandler = (userID) => {
    resendActivation(userID)
  }

  useEffect(() => {
    fetchUsers()
    fetchRoles()
  }, [
    fetchUsers,
    fetchRoles,
  ])

  useEffect(() => {
    if (isEmpty(users.value)) return
    setUsersData([...users.value])
  }, [users])

  const loading =
    users.loading ||
    roles.loading || !roles.value || !users.value


  if (loading) return <Loader />

  const filterUsers = (users) => {
    const filteredUsers = filter(users.value, user => {
      const { firstname, lastname, email } = user

      if (firstname.toLowerCase().startsWith(search) || lastname.toLowerCase().startsWith(search) || email.toLowerCase().startsWith(search)) {
        return user
      }
    })

    return filteredUsers
  }

  const handleUserSearch = (e) => {
    const search = e.target.value

    if (search === '') {
      setSearch('')
      fetchUsers()
      return
    }

    setSearch(search)
    setUsersData(filterUsers(users))
  }

  return (
    <div className={styles.container}>
      <div className={styles.containerHead}>
        <h2>Users</h2>
      </div>

      <Form className={styles.searchbar}>
        <Row className="align-items-center">
          <Col xs="auto">
            <InputGroup className="mb-2">
              <InputGroup.Text><AiOutlineSearch /></InputGroup.Text>

              <Form.Control
                type="text"
                id="inlineFormInput"
                placeholder="Search users"
                onChange={handleUserSearch}
              />
            </InputGroup>
          </Col>
        </Row>
      </Form>
      <UserTable
        users={usersData}
        roles={roles.value}
        ticketAdd={ticketAddHandler}
        ticketRemove={ticketRemoveHandler}
        deleteUser={deleteUserHandler}
        selectedUser={selectedUser}
        setSelected={u => setSelectedUser(u)}
        changeUserRole={setRoleHandler}
        resendActivation={resendActivationHandler}
      />
    </div>
  )
}

export default connect(
  null,
  mapDispatchToProps
)(AdminUsers)
