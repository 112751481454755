import styles from "./index.module.scss";
import { connect } from "react-redux";
import Loader from "../../../components/loader";

import { actions as notificationActions } from "../../../redux/notification";
import { actions as organisationActions } from "../../../redux/organisation";

import OrganisationSettings from "./orgSettings";
import BrandingSettings from "./brandingSettings";

import {
    organisationBranding,
    organisationGet,
    updateOrganisation,
    updateOrganisationBranding,
} from "../../../lib/api";
import { useQuery } from "react-query";

function mapDispatchToProps(dispatch) {
    return {
        addNotification: (text, options) =>
            dispatch(notificationActions.addNotification(text, options)),
        getOrg: () => dispatch(organisationActions.getInfo()),
    };
}

const getBranding = async () => {
    const result = await organisationBranding();
    return result;
};

const getOrganisation = async () => {
    const result = await organisationGet();
    return result;
};

function Settings({ addNotification }) {
    const {
        data: branding = [],
        isLoading: brandingLoading,
        refetch: getBrandingData,
    } = useQuery({
        queryFn: getBranding,
    });

    const {
        data: organisation = {},
        isLoading: organisationLoading,
        refetch: getOrganisationData,
    } = useQuery({
        queryFn: getOrganisation,
    });

    const updateOrganisationHandler = async (organisation) => {
        await updateOrganisation(organisation);
        addNotification("organisation settings saved");
        await getOrganisationData();
    };

    const updateOrganisationBrandingHandler = async (branding) => {
        await updateOrganisationBranding(branding);
        addNotification("organisation settings saved");
        await getBrandingData();
    };

    if (brandingLoading || organisationLoading) {
        return <Loader />;
    }

    return (
        <div className={styles.container}>
            <h2>Settings {organisation.name}</h2>

            <OrganisationSettings
                organisation={organisation}
                updateOrganisation={updateOrganisationHandler}
            />
            <BrandingSettings
                branding={branding}
                updateOrganisationBranding={updateOrganisationBrandingHandler}
            />
        </div>
    );
}

export default connect(null, mapDispatchToProps)(Settings);
