import { useState, useEffect } from "react"
import { Row, Col, Card, Form, Button } from "react-bootstrap"
import { format } from "date-fns"
import { isEmpty, map, slice, get } from "lodash"
import ReactPaginate from "react-paginate"
import { BiFirstPage, BiLastPage } from "react-icons/bi"
import { FaChevronRight } from "react-icons/fa"
import { useNavigate } from "react-router"
import { connect } from "react-redux"

import DatePickerDialog from "./picker"
import Loader from "../../components/loader"

import config from "../../config"
import styles from "./new.module.scss"

import "react-day-picker/dist/style.css"

const { REACT_APP_BACKEND_URL } = config

const CARDS_PER_PAGE = 6

function mapStateToProps(state) {
  return {
    organisation: state.organisation.data || {},
    user: state.user.data,
  }
}

function NewReservation({
  availability,
  resources,
  loading,
  selectedResource,
  selectedBooking,
  handleResourceSelect,
  handleBookingSelect,
  startDate,
  handleStartDateSelect,
  user,
}) {
  const navigate = useNavigate()
  const [availableBookings, setAvailableBookings] = useState([])

  useEffect(() => {
    if (!availability || (isEmpty(availability) && isEmpty(selectedResource))) return
    const resourceAvailability = availability[selectedResource.resourceID]

    setAvailableBookings(get(resourceAvailability, "availableBookings", []))
  }, [availability, setAvailableBookings, selectedResource])

  function openDetail() {
    navigate("/reservations/confirm")
  }

  const canMakeBooking = !isEmpty(selectedResource) && !isEmpty(selectedBooking)

  const emptyProfile =
    user.street === null ||
    user.streetNumber === null ||
    user.postalCode === null ||
    user.telephone == null

  return (
    <>
      <Row className={styles.newRow}>
        <Col lg={6} xl={6} className={styles.left}>
          <img
            alt="banner"
            src={`${REACT_APP_BACKEND_URL}/branding/images/banner2.png`}
          />
        </Col>
        <Col lg={6} xl={6} xs={12} className={styles.right}>
          {emptyProfile ? (
            <div className={styles.messageForm}>
              <h2>Please enter all your profile information before booking!</h2>
              <a href="/profile">Take me there</a>
            </div>
          ) : (
            <>
              <Form className={styles.inputForm}>
                <h4>Book a reservation</h4>
                <Form.Group className={styles.formInput}>
                  <Form.Label>Select a date</Form.Label>
                  <DatePickerDialog
                    selectDay={handleStartDateSelect}
                    day={startDate}
                  />
                </Form.Group>
                <ResourceSelect
                  selectedResource={selectedResource}
                  resources={resources}
                  handleChange={handleResourceSelect}
                />
              </Form>
              <AvailabilityList
                availableBookings={availableBookings}
                selectedBooking={selectedBooking}
                handleBookingSelect={handleBookingSelect}
                resources={resources}
                handleChange={handleBookingSelect}
                loading={loading}
                canMakeBooking={canMakeBooking}
                handleCreateBooking={openDetail}
              />
            </>
          )}
        </Col>
      </Row>
    </>
  )
}

const ResourceSelect = ({ selectedResource, resources, handleChange }) => {
  let disabled = resources.length === 1
  return (
    <Form.Group className={styles.formInput}>
      <Form.Label>Select a boat</Form.Label>
      <Form.Select
        disabled={disabled}
        className={styles.select}
        value={selectedResource.resourceID}
        onChange={(e) => handleChange(e.target.value)}
      >
        {map(resources, (resource) => {
          const { resourceID, name } = resource
          return (
            <option key={resourceID} value={resourceID}>
              {name}
            </option>
          )
        })}
      </Form.Select>
    </Form.Group>
  )
}

const AvailabilityList = ({
  availableBookings = {},
  selectedBooking,
  handleBookingSelect,
  canMakeBooking,
  loading,
  handleCreateBooking,
}) => {
  const [pageBookings, setPageBookings] = useState([])
  const pageCount = Math.ceil(availableBookings.length / CARDS_PER_PAGE)

  useEffect(() => {
    setPageBookings(getBookingsForPage(availableBookings, 0))
  }, [availableBookings])

  if (loading) return <Loader />

  if (isEmpty(availableBookings)) {
    return (
      <div className={styles.textCenter}>
        <span>No available booking slots for this day...</span>
      </div>
    )
  }

  function handlePageClick({ selected = 0 }) {
    setPageBookings(getBookingsForPage(availableBookings, selected))
  }

  return (
    <div className={styles.timeslotSelection}>
      <span>Select a timeslot</span>
      <Row className={`${styles.timescontainer}`}>
        {map(pageBookings, (booking, index) => {
          const { start, end } = booking
          const isSelected =
            start === selectedBooking.start && end === selectedBooking.end

          return (
            <Col
              className={styles.cardContainer}
              xs={4}
              md={4}
              lg={4}
              key={index}
            >
              <Card
                border={isSelected ? "primary" : ""}
                onClick={() => handleBookingSelect(booking)}
                className={`${styles.card}`}
              >
                <span className={styles.timeslot}>
                  {format(start, "HH:mm ")}
                </span>
              </Card>
            </Col>
          )
        })}
      </Row>
      <ReactPaginate
        pageCount={pageCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={0}
        onPageChange={handlePageClick}
        previousLabel={<BiFirstPage />}
        nextLabel={<BiLastPage />}
        containerClassName={styles["paginate-container"]}
        activeClassName={styles["paginate-active"]}
        disabledClassName={styles["paginate-disabled"]}
      />
      <Button
        className={styles.reservationButton}
        onClick={() => handleCreateBooking()}
        disabled={!canMakeBooking}
      >
        <p>Book now</p>
        <FaChevronRight />
      </Button>
    </div>
  )
}

function getBookingsForPage(bookings, page) {
  const startIndex = CARDS_PER_PAGE * page
  return slice(bookings, startIndex, startIndex + CARDS_PER_PAGE)
}

export default connect(mapStateToProps, null)(NewReservation)
