import { useCallback, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router"

function useQuery() {
  const [query, setQuery] = useState(new URLSearchParams())
  const { search } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    setQuery(new URLSearchParams(search))
  }, [search])

  const deleteFromQuery = useCallback((key) => {
    // prevent state mutation
    const newQuery = new URLSearchParams(query.toString)
    newQuery.delete(key)

    navigate(newQuery.toString())
  }, [query, navigate])

  return {
    deleteFromQuery,
    query
  }
}

export default useQuery
