import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { get } from 'lodash'

import { profile } from '../lib/api'

const getProfile = createAsyncThunk(
  'user/profile',
  async () => {
    const response = await profile()
    return response
  }
)

// Then, handle actions in your reducers:
const slice = createSlice({
  name: 'user',
  initialState: {
    data: {},
    loading: false,
    error: ''
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProfile.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(getProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProfile.rejected, (state, action) => {
        state.error = get(action, 'payload.response.data', 'something went wrong');
        state.loading = false;
      });
  },
})

export const { reducer } = slice

export const actions = {
  getProfile
}
