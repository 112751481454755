import { useState } from 'react'
import { Table } from 'react-bootstrap'
import { BsPlusCircleFill } from 'react-icons/bs'
import { RiDeleteBinLine, RiEditBoxFill } from 'react-icons/ri'

import ResourceCreateEditModal from './resource-create-edit-modal'
import ResourceDeleteModal from './resource-delete-modal'

import styles from './index.module.scss'
import { find, omit } from 'lodash'

function Resources({ resources, createResource, saveResource, deleteResource, calendars, roles }) {
  if (!resources || !calendars || !roles) return

  const [showResourceCreateModal, setShowResourceCreateModal] = useState(false)

  const [resourceToDelete, setResourceToDelete] = useState({})
  const [showResourceDeleteModal, setShowResourceDeleteModal] = useState(false)
  const [resourceToEdit, setResourceToEdit] = useState({})
  const [showResourceEditModal, setShowResourceEditModal] = useState(false)

  return (
    <>
      <div className={styles.tableHeader}>
        <h2>Resources</h2>
        <BsPlusCircleFill onClick={() => setShowResourceCreateModal(true)} />
      </div>
      <Table hover borderless={true} striped={true}>
        <thead>
          <tr>
            <th>Calendar name</th>
            <th>Openinghours linked</th>
            <th>Description</th>
            <th>Roles</th>
            <th>Is Free</th>
            <th>Capacity</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {resources.map(resource => {
            const coupledRoles = []
            resource.roles.forEach(role => {
              const foundRole = find(roles, r => r.roleID === role.roleID)
              if (foundRole) {
                coupledRoles.push(foundRole.name)
              }
            })
            const calendar = find(calendars, c => c.calendarID === resource.calendarID)
            return (
              <tr key={resource.resourceID}>
                <td>{resource.name}</td>
                <td>{calendar.name}</td>
                <td>{resource.description}</td>
                <td>{coupledRoles.join(',')}</td>
                {resource.isFree === 1 ? (
                  <td>Yes</td>
                ) : (
                  <td>No</td>
                )}
                <td>{resource.capacity}</td>

                <td>
                  <RiDeleteBinLine
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setResourceToDelete(resource)
                      setShowResourceDeleteModal(true)
                    }}
                  />
                  <RiEditBoxFill
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setResourceToEdit(resource)
                      setShowResourceEditModal(true)
                    }}
                  />
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>

      <ResourceDeleteModal
        show={showResourceDeleteModal}
        resource={resourceToDelete}
        deleteResource={() => {
          setShowResourceDeleteModal(false)
          deleteResource(resourceToDelete.resourceID)
        }}
        handleClose={() => setShowResourceDeleteModal(false)}
      />

      <ResourceCreateEditModal
        resource={undefined}
        show={showResourceCreateModal}
        saveResource={(values) => {
          setShowResourceCreateModal(false)
          createResource(values)
        }}
        handleClose={() => setShowResourceCreateModal(false)}
        calendars={calendars}
        roles={roles}
      />

      {resourceToEdit && (
        <ResourceCreateEditModal
          resource={resourceToEdit}
          show={showResourceEditModal}
          saveResource={(values) => {
            setShowResourceEditModal(false)
            saveResource(resourceToEdit.resourceID, omit(values, ['availabilityConstraints']))
          }}
          handleClose={() => setShowResourceEditModal(false)}
          calendars={calendars}
          roles={roles}
        />
      )}
    </>
  )
}

export default Resources
