import { BrowserRouter, Route, Routes } from "react-router";
import { connect } from "react-redux";
import { Toaster } from "react-hot-toast";

import Main from "./containers/main";
import Login from "./containers/login";
import Register from "./containers/register";
import ResetPassword from "./pages/password-reset";

import styles from "./app.module.scss";
import { QueryClient, QueryClientProvider } from "react-query";
import { useEffect } from "react";
import { errToast, successToast } from "./lib/toast";

const queryClient = new QueryClient();

function mapStateToProps(state) {
    return {
        notification: state.notification,
    };
}

function App({ notification }) {
    useEffect(() => {
        if (!notification?.options?.appearance) return;

        if (notification.options.appearance === "error") {
            errToast(notification.text);
        } else {
            successToast(notification.text);
        }
    }, [notification]);
    return (
        <div className={styles.body}>
            <div className={styles.container}>
                <QueryClientProvider client={queryClient}>
                    <Content />
                    <Toaster position="bottom-right" />
                </QueryClientProvider>
            </div>
        </div>
    );
}

function Content() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/*" element={<Main />} />
                <Route path="login" element={<Login />} />
                <Route path="reset-password" element={<ResetPassword />} />
                <Route path="register" element={<Register />} />
            </Routes>
        </BrowserRouter>
    );
}

export default connect(mapStateToProps)(App);
