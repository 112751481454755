import { useEffect } from 'react'
import { actions as notificationActions } from '../../../redux/notification'
import { connect } from 'react-redux'
import { useAsyncFn } from 'react-use'

import TicketPrices from './ticketPrices/index'
import Roles from './roles/index'
import RolePrices from './role-prices/index'
import Loader from '../../../components/loader'

import styles from './index.module.scss'

import {
  getRoles,
  getTicketPricesList,
  getRolePricesList,
  createTicketPrice,
  deleteTicketPrice,
  updateTicketPrice,
  createRole,
  deleteRole,
  updateRole,
  createRolePrice,
  deleteRolePrice,
  updateRolePrice,
  assignResourcesToRole
} from '../../../lib/api'
import omit from 'lodash/omit'

const rolesFetcher = () => getRoles()
const ticketPricesFetcher = () => getTicketPricesList()
const rolePricesFetcher = () => getRolePricesList()

function mapDispatchToProps(dispatch) {
  return {
    addNotification: (text, options) => dispatch(notificationActions.addNotification(text, options)),
  }
}

function Prices({ addNotification }) {
  const [roles, fetchRoles] = useAsyncFn(getRoles)
  const [ticketPrices, fetchTicketPrices] = useAsyncFn(getTicketPricesList)
  const [rolePrices, fetchRolePrices] = useAsyncFn(getRolePricesList)

  useEffect(() => {
    fetchTicketPrices()
    fetchRoles()
    fetchRolePrices()
  }, [
    fetchTicketPrices,
    fetchRoles,
    fetchRolePrices
  ])

  const createTicketPriceHandler = async (values) => {
    await createTicketPrice(values)
    fetchTicketPrices()
    addNotification('ticket price created')
  }

  const deleteTicketPriceHandler = async (ticketPriceID) => {
    await deleteTicketPrice(ticketPriceID)
    fetchTicketPrices()
    addNotification('ticket price deleted')
  }

  const updateTicketPriceHandler = async (values) => {
    await updateTicketPrice(values.ticketPriceID, omit(values, ['ticketPriceID', 'organisationID']))
    fetchTicketPrices()
    addNotification('ticket price edited')
  }

  const createRoleHandler = async (values) => {
    await createRole(values)
    fetchRoles()
    addNotification('role created')
  }

  const updateRoleHandler = async (values) => {
    await updateRole(values.roleID, omit(values, ['roleID', 'organisationID', 'resources']))
    fetchRoles()
    addNotification('role created')
  }

  const deleteRoleHandler = async (roleID) => {
    await deleteRole(roleID)
    fetchRoles()
    addNotification('role deleted')
  }

  const assignResourcesToRoleHandler = async (values) => {
    await assignResourcesToRole(values.roleID, values.resourceIDs)
    fetchRoles()
  }

  const createRolePriceHandler = async (values) => {
    await createRolePrice(values)
    fetchRolePrices()
    addNotification('role price created')
  }

  const deleteRolePriceHandler = async (rolePriceID) => {
    await deleteRolePrice(rolePriceID)
    fetchRolePrices()
    addNotification('role price deleted')
  }

  const updateRolePriceHandler = async (values) => {
    await updateRolePrice(values.rolePriceID, omit(values, ['rolePriceID', 'organisationID', 'parsedDuration']))
    fetchRolePrices()
    addNotification('role price updated')
  }

  const loading =
    roles.loading ||
    ticketPrices.loading ||
    rolePrices.loading || !roles.value || !ticketPrices.value || !rolePrices.value

  if (loading) return <Loader />

  return (
    <div className={styles.container}>
      <TicketPrices
        ticketPrices={ticketPrices.value}
        createTicketPrice={createTicketPriceHandler}
        deleteTicketPrice={deleteTicketPriceHandler}
        updateTicketPrice={updateTicketPriceHandler}
        roles={roles.value}
      />
      <br />
      <Roles
        roles={roles.value}
        createRole={createRoleHandler}
        deleteRole={deleteRoleHandler}
        updateRole={updateRoleHandler}
        assignResourcesToRole={assignResourcesToRoleHandler}
      />
      <br />
      <RolePrices
        rolePrices={rolePrices.value}
        roles={roles.value}
        createRolePrice={createRolePriceHandler}
        deleteRolePrice={deleteRolePriceHandler}
        updateRolePrice={updateRolePriceHandler}
      />
    </div>
  )
}

export default connect(
  null,
  mapDispatchToProps
)(Prices)
