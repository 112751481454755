import { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router";
import { useAsync, useAsyncFn } from "react-use";
import { find, isEmpty } from "lodash";

import New from "./new";
import List from "./list";
import Detail from "./detail";

import {
  getResources,
  getAvailability,
  getBookings,
  profile,
} from "../../lib/api";
import Loader from "../../components/loader";
import Banner from "../../components/banner";
import moment from "moment";

import styles from "./index.module.scss";

function Reservations() {
  const location = useLocation();

  const [startDate, setStartDate] = useState(new Date());
  const [selectedResource, setSelectedResource] = useState({});
  const [selectedBooking, setSelectedBooking] = useState({});

  const { value: resources, loading: resourcesLoading } = useAsync(
    async () => {
      return await getResources();
    }
  );

  const [availabilityState, fetchAvailability] = useAsyncFn(
    async (resourceID, from) => {
      const av = await getAvailability([resourceID], from);
      return av;
    },
    []
  );

  const [bookingsState, fetchBookings] = useAsyncFn(async () => {
    const bookings = await getBookings();
    return bookings;
  });

  const [userState, getUser] = useAsyncFn(async () => {
    return await profile();
  });

  useEffect(() => {
    fetchBookings();
  }, [fetchBookings]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  useEffect(() => {
    if (!isEmpty(selectedResource) || isEmpty(resources)) return;
    setSelectedResource(resources[0]);
  }, [resources, setSelectedResource, selectedResource]);

  useEffect(() => {
    if (isEmpty(selectedResource)) return;
    const start = moment(startDate).startOf("day").valueOf();
    fetchAvailability(selectedResource.resourceID, start);
  }, [selectedResource, startDate, fetchAvailability]);

  function handleResourceSelect(id) {
    const fullResource = find(
      resources,
      ({ resourceID }) => resourceID === Number(id)
    );
    setSelectedBooking({});
    setSelectedResource(fullResource);
  }

  function handleBookingSelect(booking) {
    setSelectedBooking(booking);
  }

  if (resourcesLoading) return <Loader />;

  const loading =
    availabilityState.loading || bookingsState.loading || userState.loading;

  return (
    <>
      {location.pathname !== "/" && <Banner />}
      <div className={styles.container}>
        <Routes>
          <Route
            index
            element={
              <New
                resources={resources}
                loading={loading}
                selectedResource={selectedResource}
                selectedBooking={selectedBooking}
                handleResourceSelect={handleResourceSelect}
                handleBookingSelect={handleBookingSelect}
                startDate={startDate}
                handleStartDateSelect={setStartDate}
                availability={availabilityState.value}
              />
            }
          />
          <Route
            path="confirm"
            element={
              <Detail
                booking={selectedBooking}
                resource={selectedResource}
                getBookings={fetchBookings}
                user={userState.value}
              />
            }
          />
          <Route
            path="/list"
            element={
              <List
                resources={resources}
                bookings={bookingsState.value}
                getBookings={fetchBookings}
                loading={loading}
              />
            }
          />
        </Routes>
      </div>
    </>
  );
}

export default Reservations;
