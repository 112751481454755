import { Nav, Navbar } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";
import { connect } from "react-redux";
import { BiLogOut } from "react-icons/bi";
import { isAdmin, logout } from "../lib/auth";
import config from "../config";

import styles from "./navbar.module.scss";

const { REACT_APP_BACKEND_URL } = config;

function mapStateToProps(state) {
  return {
    user: state.user,
    organisation: state.organisation.data || {},
  };
}

const NavigationBar = ({ organisation }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavClick = (key, e) => {
    e.preventDefault();
    e.stopPropagation();
    key && navigate(key);
  };

  const { name: orgName } = organisation;

  return (
    <>
      <Navbar className={styles.navbar} collapseOnSelect expand="md">
        <Navbar.Brand
          onClick={(e) => handleNavClick("/", e)}
          className={styles.navBrand}
        >
          <div>
            <img
              alt=""
              src={`${REACT_APP_BACKEND_URL}/branding/images/logo.png`}
            />
            <span>{orgName}</span>
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav
            defaultActiveKey={location.pathname}
            activeKey={location.pathname}
            onSelect={handleNavClick}
            className={`${styles.navRight} w-100`}
          >
            <Nav.Item className="ml-md-auto">
              <Nav.Link eventKey="/tickets">My tickets</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="/reservations/list">
                My reservations
              </Nav.Link>
            </Nav.Item>
            {organisation.googleCalendarURL && (
              <Nav.Item>
                <Nav.Link eventKey="/calendar">
                  Calendar
                </Nav.Link>
              </Nav.Item>
            )}
            {isAdmin() ? (
              <Nav.Item>
                <Nav.Link eventKey="/admin">Admin</Nav.Link>
              </Nav.Item>
            ) : (
              <></>
            )}
            <Nav.Item>
              <Nav.Link eventKey="/profile">Profile</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                onClick={() => logout(location)}
                eventKey="/login"
              >
                Sign Out
                <BiLogOut style={{ fontSize: 20 }} />
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default connect(mapStateToProps)(NavigationBar);
