import React, { useState } from 'react'

import { format } from 'date-fns'
import { DayPicker } from 'react-day-picker'

import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useDismiss,
  useRole,
  useClick,
  useInteractions,
  FloatingFocusManager,
  useId
} from "@floating-ui/react";

import 'react-day-picker/dist/style.css'
import './new-picker.scss'

export default function DatePickerDialog({ day, selectDay }) {
  const [isPopperOpen, setIsPopperOpen] = useState(false)

  const { refs, floatingStyles, context } = useFloating({
    open: isPopperOpen,
    onOpenChange: setIsPopperOpen,
    placement: "right-start",
    middleware: [
      offset(500),
    ],
    whileElementsMounted: autoUpdate
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role
  ]);

  const headingId = useId();

  const closePopper = () => {
    setIsPopperOpen(false)
    // document.getElementById('datepickerinput').blur()
  }

  const openPopper = () => {
    setIsPopperOpen(true)
  }

  return (
    <div>
      <div className={'dateInput'} {...getReferenceProps()}>
        <span>{format(day, 'dd-MM-y')}</span>
      </div>
      {isPopperOpen && (
        <FloatingFocusManager context={context}>
          <div
            className="dialog-sheet"
            ref={refs.setFloating}
            style={floatingStyles}
            aria-labelledby={headingId}
            {...getFloatingProps()}
          >
            <DayPicker
              autoFocus={isPopperOpen}
              mode="single"
              defaultMonth={day}
              selected={day}
              onSelect={(e) => {
                if (!e) return
                closePopper()
                selectDay(e)
              }}
              weekStartsOn={1}
            />
          </div>
        </FloatingFocusManager>
      )}
    </div>
  )
}
