import { Routes, Route } from 'react-router'
import BookingsList from './bookings'
import AdminCalendar from './calendar'
import Resources from './resources'
import Users from './users'
import Settings from './settings'
import Prices from './prices'
import Openinghours from './openinghours'

import Sidebar from "./sidebar"
import Constraints from './openinghours'

function Admin() {
  return (
    <div style={{ height: '100%', display: 'flex' }}>
      <Sidebar />
      <div style={{ flex: 1, display: 'flex', margin: '1em', overflow: 'auto' }}>
        <Routes>
          <Route path="/" element={<AdminCalendar />} />
          <Route path="calendar" element={<AdminCalendar />} />
          <Route path="bookings" element={<BookingsList />} />
          <Route path="resources" element={<Resources />} />
          <Route path="openinghours" element={<Openinghours />} />
          <Route path="resources/:id" element={<Constraints />} />
          <Route path="users" element={<Users />} />
          <Route path="prices" element={<Prices />} />
          <Route path="settings" element={<Settings />} />
        </Routes>
      </div>
    </div>
  )
}

export default Admin
