import { useEffect } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { AiOutlineInfoCircle, AiOutlineCheck } from "react-icons/ai"
import { CgProfile } from 'react-icons/cg'
import { FaChevronRight } from "react-icons/fa"
import { map, isEmpty, reduce } from 'lodash'
import { connect } from 'react-redux'
import { useAsyncFn } from 'react-use'

import { createTicketPayment, createRolePayment } from '../../lib/api'
import Loader from '../../components/loader'

import config from '../../config'

import styles from './buy.module.scss'

const {
  REACT_APP_BACKEND_URL
} = config

const ticketPaymentFetcher = ([ticketPriceID]) => createTicketPayment(ticketPriceID)
const rolePaymentFetcher = ([rolePriceID]) => createRolePayment(rolePriceID)

function mapStateToProps(state) {
  return {
    organisation: state.organisation.data || {}
  }
}

function TicketsBuy({ loading, ticketPrices, resources, rolePrices, organisation }) {
  const [ticketUrlData, fetchTicketUrl] = useAsyncFn(async (ticketPriceId) => {
    return await ticketPaymentFetcher([ticketPriceId])
  })

  const [roleUrlData, fetchRoleUrl] = useAsyncFn(async (rolePriceId) => {
    return await rolePaymentFetcher([rolePriceId])
  })

  useEffect(() => {
    if (ticketUrlData.value) window.location.href = ticketUrlData.value
    if (roleUrlData.value) window.location.href = roleUrlData.value
  }, [ticketUrlData, roleUrlData])

  useEffect(() => {
    console.log('scrolling to top')
    window.scrollTo(10, 10)
  }, [])

  function handleBuyClick(type, id) {
    switch (type) {
      case 'ticket':
        fetchTicketUrl(id)
        break
      case 'role':
        fetchRoleUrl(id)
        break
      default:
        return
    }
  }

  if (loading) return <Loader />

  const paymentButtonDisabled =
    ticketUrlData.value || roleUrlData.value ||
    ticketUrlData.loading || roleUrlData.loading

  return (
    <>
      <h3>Tickets</h3>
      <h2 className={styles.blackTitle}>Buy Tickets</h2>
      <Prices
        ticketPrices={ticketPrices}
        handleBuyClick={handleBuyClick}
        rolePrices={rolePrices}
        buttonDisabled={paymentButtonDisabled}
        organisation={organisation}
        resources={resources}
      />
    </>
  )
}

function Prices({ ticketPrices, handleBuyClick, rolePrices, buttonDisabled, organisation, resources }) {
  return (
    <Row>
      {
        map(ticketPrices, (ticketPrice, index) => {
          return (
            <TicketPriceCard
              ticketPrice={ticketPrice}
              handleBuyClick={handleBuyClick}
              buttonDisabled={buttonDisabled}
              organisation={organisation}
              resources={resources}
              key={index}
            />
          )
        })
      }
      {
        !isEmpty(rolePrices)
          ? rolePrices.map(rolePrice => {
            const { name, price, rolePriceID } = rolePrice

            return (
              <Col xs={12} lg={6} xl={4} className={styles.cardCol} key={rolePriceID}>
                <div className={styles.rolePriceCard}>
                  <CgProfile />
                  <p>Become a {name}</p>
                  <ul>
                    <li><AiOutlineCheck /> Save money by becomming a {name}</li>
                    <li><AiOutlineCheck /> For only € {price}</li>
                    {/* <li><AiOutlineCheck /> {description}</li> */}
                  </ul>
                  <Button onClick={() => handleBuyClick('role', rolePriceID)} disabled={buttonDisabled}>
                    <span>Become a {name}</span>
                    <FaChevronRight />
                  </Button>
                </div>
              </Col>
            )
          })
          : <Col xs={12} lg={6} xl={4} className={styles.cardCol}>
            <div className={styles.infoCard}>
              <img alt='skier' src={`${REACT_APP_BACKEND_URL}/branding/images/buy-card.png`} />
              <img alt='mustache' src='/big_mustache.svg' />
            </div>
          </Col>
      }
    </Row>
  )
}

const TicketPriceCard = ({ ticketPrice, handleBuyClick, buttonDisabled, organisation, resources, index }) => {
  const { name, price, ticketAmount, ticketPriceID } = ticketPrice

  const sessionString = `Session${ticketAmount > 1 ? 's' : ''}`

  const resourcesString = reduce(resources, (acc, resource) => {
    if (!acc) return resource.name
    return `${acc}, ${resource.name}`
  }, '')

  return (
    <Col xs={12} lg={6} xl={4} className={styles.cardCol} key={index}>
      <div className={styles.priceCard}>
        <div className={styles.upperCardRow}>
          <div>
            <p>{ticketAmount}-{sessionString}</p>
            <p className={styles.price}>€ {price}</p>
          </div>
        </div>
        <div className={styles.lowerCardRow}>
          <AiOutlineInfoCircle />
          <p>{name}</p>
        </div>
        <span className={styles.detailText}>
          This ticket is valid for {ticketAmount} {sessionString} with <strong>{resourcesString}</strong> at {organisation.name}. These tickets have no expiration date and are not refundable.
        </span>
        <Button className={styles.button} onClick={() => handleBuyClick('ticket', ticketPriceID)} disabled={buttonDisabled}>
          <span>Buy Tickets</span>
          <FaChevronRight />
        </Button>
      </div>
    </Col>
  )
}

export default connect(
  mapStateToProps
)(TicketsBuy)
