import { Nav } from 'react-bootstrap'
import { AiOutlineSetting, AiOutlineClockCircle } from 'react-icons/ai'
import { BsCalendarDate, BsListNested } from 'react-icons/bs'
import { FiUsers } from 'react-icons/fi'
import { ImPriceTags } from 'react-icons/im'
import { useLocation, NavLink } from 'react-router'

import styles from './sidebar.module.scss'

function Sidebar() {
  const location = useLocation()

  console.log(location.pathname)
  return (
    <div className={styles.sidebar}>
      {/* <h2>Admin</h2> */}
      <Nav defaultActiveKey="/admin/calendar" activeKey={location.pathname} className="flex-column">
        <NavLink activeClassName={styles.activeLink} className={styles.navlink} to="/admin/calendar">
          <BsCalendarDate />
          Overview
        </NavLink>
        <NavLink activeClassName={styles.activeLink} className={styles.navlink} to="/admin/bookings">
          <BsListNested />
          Bookings
        </NavLink>
        <NavLink activeClassName={styles.activeLink} className={styles.navlink} to="/admin/resources">
          <BsCalendarDate />
          Resources
        </NavLink>
        <NavLink activeClassName={styles.activeLink} className={styles.navlink} to="/admin/openinghours">
          <AiOutlineClockCircle />
          Openinghours
        </NavLink>
        <NavLink activeClassName={styles.activeLink} className={styles.navlink} to="/admin/users">
          <FiUsers />
          Users
        </NavLink>
        <NavLink activeClassName={styles.activeLink} className={styles.navlink} to="/admin/prices">
          <ImPriceTags />
          Prices
        </NavLink>
        <NavLink activeClassName={styles.activeLink} className={styles.navlink} to="/admin/settings">
          <AiOutlineSetting />
          Settings
        </NavLink>
      </Nav>
    </div>
  )
}

export default Sidebar
