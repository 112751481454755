import React from "react";
import { Provider } from "react-redux";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import store from "./redux/store";
import config from "./config";

import "./index.scss";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

const { REACT_APP_BACKEND_URL } = config;

// This is the ID of the div in your index.html file

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <StrictMode>
        <Provider store={store}>
            <link
                rel="stylesheet"
                href={`${REACT_APP_BACKEND_URL}/branding/branding.css`}
            />
            <App />
        </Provider>
        ,
    </StrictMode>
);
