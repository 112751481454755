import { useEffect } from "react";
import { connect } from "react-redux";
import { Route, useNavigate, Routes, Navigate } from "react-router";
import { isEmpty } from "lodash";

import Navbar from "../components/navbar";
import Unknown from "../pages/unknown";
import Profile from "../pages/profile";
import Tickets from "../pages/tickets";
import Reservations from "../pages/reservations";
import Admin from "../pages/admin";
import Calendar from "../pages/googleCalendar";

import Loader from "../components/loader";

import { checkLoginState } from "../lib/auth";
import { initStore } from "../redux/store";

import styles from "./main.module.scss";

function mapStateToProps(state) {
  return {
    user: state.user,
    organisation: state.organisation,
  };
}

function Main({ user, organisation }) {
  const navigate = useNavigate();

  const { loading: userLoading, data: userData, error: userError } = user;

  const {
    loading: orgLoading,
    data: orgData,
    error: orgError,
  } = organisation;

  useEffect(() => {
    if (!checkLoginState() || !isEmpty(userError) || !isEmpty(orgError)) {
      navigate("/login");
    } else {
      initStore();
    }
  }, []);

  if (isEmpty(userData) || userLoading || (orgLoading && isEmpty(orgData)))
    return <Loader />;

  return (
    <div className={styles.wrapper}>
      <Navbar />
      <div className={styles.page}>
        <Routes>
          <Route
            path="/"
            element={<Navigate to="/reservations" replace />}
          />
          <Route path="reservations/*" element={<Reservations />} />
          {orgData.googleCalendarURL && (
            <Route path="calendar" element={<Calendar />} />
          )}
          <Route path="tickets/*" element={<Tickets />} />
          <Route path="profile" element={<Profile />} />
          <Route path="admin/*" element={<Admin />} />
          <Route path="*" element={<Unknown />} />
        </Routes>
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(Main);
