import { map } from 'lodash'
import { Col, Row, Button } from 'react-bootstrap'
import { find, groupBy } from 'lodash'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import moment from 'moment'
import { format } from 'date-fns'

import { deleteBookings } from '../../lib/api'
import Loader from '../../components/loader'
import ReservationCard from '../../components/reservation-card'
import ConfirmDeleteModal from '../../components/confirm-delete-modal'
import styles from './list.module.scss'
import { successToast } from '../../lib/toast'

function ReservationList({ resources, loading, bookings, getBookings }) {
  const navigate = useNavigate()

  const [showDeleteModal, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const [bookingToDelete, setBookingToDelete] = useState(undefined)

  if (loading) return <Loader />

  const handleShowDeleteConfirm = (id) => {
    setShow(true)
    setBookingToDelete(id)
  }

  const handleDelete = () => {
    deleteBookings(bookingToDelete)
      .then(_ => {
        successToast('cancelled booking')
        getBookings()
        setShow(false)
        setBookingToDelete(undefined)
      })
  }

  const hasBookings = bookings.length > 0
  bookings = bookings.sort((a, b) => a.start - b.start)
  bookings = groupBy(bookings, b => moment(b.start).startOf('day'))

  return (
    <>
      <div className={styles.container}>
        <h3>Reservations</h3>
        <h2>My Reservations</h2>
        {map(bookings, (groupedBookings, day) => {
          return (
            <div style={{ marginTop: '1em' }} key={moment(day).valueOf()}>
              <h4>
                {format(new Date(day), 'EEEE dd-MM-yyyy')}
              </h4>
              <Row>
                {
                  map(groupedBookings, booking => {
                    const { start, end, resourceID, bookingID } = booking
                    const fullResource = find(resources, resource => resource.resourceID === resourceID)
                    if (!fullResource) return null
                    const { name, capacity } = fullResource

                    return (
                      <Col xs={12} lg={3} sm={3} key={start} className='px-1'>
                        <ReservationCard
                          name={name || 'no name'}
                          start={start}
                          end={end}
                          capacity={capacity}
                          bookingID={bookingID}
                          width={'100%'}
                          handleShowDeleteConfirm={handleShowDeleteConfirm}
                        />
                        <ConfirmDeleteModal show={showDeleteModal} handleClose={handleClose} handleDelete={handleDelete} />
                      </Col>
                    )
                  })
                }
              </Row>
            </div>
          )
        })}
        {!hasBookings && (
          <div>
            <Button className={styles.createBookingButton} onClick={() => navigate('/reservations')}>
              <span>Create a booking now</span>
            </Button>
          </div>
        )}
      </div>

    </>
  )
}

export default ReservationList
