import { Button, Spinner } from "react-bootstrap";
import { createBooking } from "../../lib/api";
import ReservationCard from "../../components/reservation-card";
import Loader from "../../components/loader";
import { FaChevronRight } from "react-icons/fa";
import { useNavigate, redirect } from "react-router";
import { isEmpty } from "lodash";
import { useAsync, useAsyncFn } from "react-use";
import { successToast, errToast } from "../../lib/toast";

import styles from "./detail.module.scss";

function DetailReservation({
  booking,
  resource,
  user,
  loading,
  getBookings,
}) {
  const { name, capacity } = resource;
  const { start, end } = booking;
  const navigate = useNavigate();

  if (!start && !end) return navigate("/reservations");

  const createBookingHandler = async ([ticketPriceID]) => {
    try {
      // Attempt to create a booking
      await createBooking(ticketPriceID);

      // If successful, fetch updated bookings and navigate
      getBookings();
      successToast("Booking created!");
      navigate("/reservations/list");
    } catch (err) {
      // Handle any errors that occur during booking creation
      console.error("Error creating booking:", err);
      if (err.status === 409) {
        errToast("You or someone else already has a booking for this time.");
      } else {
        errToast(err.response.data.message);
      }
    }
  };

  const [bookingStatus, doCreateBooking] = useAsyncFn(async (ticketPriceID) => {
    return await createBookingHandler([ticketPriceID]);
  });

  if (isEmpty(resource) || isEmpty(booking))
    return redirect("/reservations");

  if (loading) return <Loader />;

  // const filteredTickets = tickets.filter(ticket => resource.resourceTypes.some(type => type.resourceTypeID === ticket.resourceTypeID))
  const hasTickets = user.tickets > 0;

  return (
    <>
      <div className={styles.detail}>
        <h3>{resource.name}</h3>
        <h2>Confirm reservation</h2>
        <ReservationCard
          name={name}
          start={start}
          end={end}
          capacity={capacity}
        />
        <div className={styles.costDetail}>
          <p>You have {user.tickets} ticket(s) left</p>
          {!hasTickets && (
            <span onClick={() => navigate("/tickets/buy")}>
              Show me where to buy tickets.
            </span>
          )}
        </div>
        <div className={styles.actionButtons}>
          <Button
            className={styles.cancelButton}
            onClick={() => navigate("/reservations")}
          >
            <span>Cancel</span>
          </Button>

          <Button
            disabled={!hasTickets}
            className={styles.confirmButton}
            onClick={() => doCreateBooking(booking)}
          >
            {bookingStatus.loading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
            {!bookingStatus.loading && <span>Confirm</span>}
            <FaChevronRight />
          </Button>
        </div>
      </div>
    </>
  );
}

export default DetailReservation;
